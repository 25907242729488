import { CrmDictionary } from 'common-module/core/types';

export const withDefaultRecordsSort = (params: CrmDictionary) => {
  const keys = Object.keys(params) ?? [];

  if (keys.some((key) => key.startsWith('$sort'))) {
    return params;
  }

  return { ...params, '$sort[createdAt]': '-1' };
};
